<template>
  <div class="shared-radio">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      :checked="modelValue === value"
      @change="$emit('change', $event.target.value)"
      :disabled="disabled"
    />
    <label :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
    },
    value: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
};
</script>

<style lang="scss" scoped>
.shared-radio {
  display: flex;
  margin-right: 24px;
  white-space: nowrap;

  &:last-child{
    margin-right: 0;
  }

  input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 14px;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color);
      border-radius: 50px;
      background: white;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
      background: var(--liff-button-color);
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(40deg);
    }
  }

  // + .shared-radio {
  //   margin-right: 24px;
  // }
}
</style>
